@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

body {
	font-family: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont,
		'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
		sans-serif;
	font-size: 16px;
	margin: 0;
}

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/utilities/api';
@import '../../../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import '../../../node_modules/bootstrap/scss/spinners';

html,
body {
	height: 100%;
	min-height: 100%;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.footer__upper__box {
	--mask: radial-gradient(34.24px at 50% 46.5px, #000 99%, #0000 101%)
			calc(50% - 60px) 0/120px 100%,
		radial-gradient(34.24px at 50% -16.5px, #0000 99%, #000 101%) 50% 30px/120px
			100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
}

.footer__lower__box {
	--mask: radial-gradient(34.24px at 50% 46.5px, #000 99%, #0000 101%)
			calc(50% - 30px) 0/120px 100%,
		radial-gradient(34.24px at 75% -16.5px, #0000 99%, #000 101%) 50% 30px/120px
			100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
}
